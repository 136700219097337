import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import RequestAppointmentForm from "../components/requestAppointmentForm"
import RequestAppointmentBanner from "../components/requestAppointmentBanner"
import FormTemplateInfo from "../components/formTemplateInfo"

export default function RequestAppointment({ data: { page } }) {
  return (
    <Layout>
        <RequestAppointmentBanner title={page.title} mobileLine1={page.mobileTitleLine1} mobileLine2={page.mobileTitleLine2}/>
        <FormTemplateInfo headline={page.headline} body={page.body}/>
        <RequestAppointmentForm formEndpoint={page.formEndpoint}/>
    </Layout>
  )
}


export const data = graphql`
  query RequestAppointmentQuery {
    page: datoCmsRequestAppointment {
      title
      mobileTitleLine1
      mobileTitleLine2
      headline
      body
      formEndpoint
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  return (
    <Seo 
      title={data.page.seo.title}
      description={data.page.seo.description}
      image={data.page.seo.image}
    />
  )
}
