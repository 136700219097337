// extracted by mini-css-extract-plugin
export var banner = "styles-module--banner--4afb6";
export var circle = "styles-module--circle--cb140";
export var colors = "\"../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var hide_mobile = "styles-module--hide_mobile--19864";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var show_mobile = "styles-module--show_mobile--7f10a";
export var square = "styles-module--square--3c5cd";
export var title = "styles-module--title--6542f";
export var triangle = "styles-module--triangle--926b8";
export var yellowBtn = "#FFE07B";