import React from "react"
import * as styles from "./styles.module.css"

const RequestAppointmentBanner = ({ title, mobileLine1, mobileLine2 }) => {
    return (
        <div className={styles.banner}>
            <div className={`${mobileLine1 ? styles.hide_mobile : ""} ${styles.title}`}>
                <h1>{title}</h1>
            </div>
            {mobileLine1 ? 
                <div className={`${styles.show_mobile} ${styles.title}`}>
                    <h1 className="visuallyhidden">{title}</h1>
                    <span className="h1">{mobileLine1}</span>
                </div>
                :
                ""
            }
            {mobileLine2 ? 
                <div className={`${styles.show_mobile} ${styles.title}`}>
                    <span className="h1">{mobileLine2}</span>
                </div>
                :
                ""
            }
            <div className={styles.circle}></div>
            <div className={styles.square}></div>
            <div className={styles.triangle}></div>
        </div>
    )
}

export default RequestAppointmentBanner