// extracted by mini-css-extract-plugin
export var borderGrey = "#A6A197";
export var colors = "\"../../assets/css/_colors.css\"";
export var custom_form = "styles-module--custom_form--f5e7a";
export var disabled = "styles-module--disabled--00455";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var lightBg = "#FFF8E8";
export var lightestPurple = "#E2DDFF";
export var locale = "styles-module--locale--25f70";
export var one_col = "styles-module--one_col--aed58";
export var other = "styles-module--other--a8941";
export var other_radio = "styles-module--other_radio--9fbcd";
export var other_text = "styles-module--other_text--b0408";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var radio = "styles-module--radio--60ede";
export var selected = "styles-module--selected--19356";
export var success_message = "styles-module--success_message--8b200";
export var two_col = "styles-module--two_col--d610b";